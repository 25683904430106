@font-face {
    font-family: Inconsolata;
    src: local('Inconsolata');
}


.i-left{
    flex: 1;
    width: 50%;
    align-items: center;
    justify-content: center;
    height: 100%;
    ;

}

.i-right{
    flex: 1;
    float: right;
    width: 50%;
    position: relative;
}

.i-left-wrapper{
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    align-items: center;
    padding: 1em 3em;
    min-height: 90vh;

}

.i-greeting{
    font-size: 2em;
}

.i-desc{
    font-size: 1.4em;
    font-weight: 300;
}

.i-name{
    font-size: 4em;
    margin: 0;
}

.i-text{
    height: 3.125em;
    font-size: 3em;
    color: #FF538F;
    display: flex;
    align-items: center;
    overflow: hidden;
    font-family: Inconsolata, monospace;
    font-weight: 600;
    margin: 0;
}

.wrap {
    border-right: 0.1em solid lightpink;
    display: block;
    margin: 0;
}


.i-img {
    width: 50vw;
    height: 100vh;
    object-fit: cover;
    position: absolute;
}

.i-img-bg{
    clip-path: polygon(32% 74%, 100% 26%, 100% 100%, 31% 100%);    background-color: #FF538F;
    width: 50vw;
    height:100vh;
    position: absolute;
    top: 0;
    right: 0;
}

@media screen and (max-width: 600px){
    .i-left{
        width: 100vw;
        min-height: unset;
        height: 60vh;

}
    .i-img-bg{
        width: 100vw;
        height: 40vh;
    }

    .i-img{
        width: 100vw;
        height: 40vh;
    }

    .i-right{
        width: 100vw;
        float: none;
        height: 40vh;
    }
    .i-left-wrapper{
        padding: 1.5em;
    }
    .i-greeting{
        font-size: 1.5em;
    }

    .i-desc{
        font-size: 1em;
        font-weight: 300;
    }

    .i-name{
        font-size: 3em;
        margin: 0;
    }

    .i-text{
        font-size: 2em;

    }
}