.contact-container{
    position: relative;

}

.c-bg{
    width: 20px;
    height: 100%;
    background-color: #FF538F;
    position: absolute;
}
.c-wrapper{
    padding: 0 5em;
    display: flex;
}

.c-left{
    flex: 1;
}

.c-right{
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.c-title{
    font-size: 3em;
}

.c-info{
    display: flex;
    align-items: center;
    margin:5em 0;
    font-width: 300;
    width: 70%;
}

.c-icon{
    width: 30px;
    height: 30px;
    margin-right: 20px;
}

.c-desc{
    font-weight: 200;
    display: block;
    text-align: left;
}

form{
    margin-top: 1.5em;
    justify-content: space-between;

}

input{
    width: 40vw;
    height: 3em;
    border: none;
    border-bottom: 1px solid #121323;
    margin: .75em 0;
    font-size: 14px;
}
.input-row1{
    width: 19vw;
    margin-right: 1.3em;
}

textarea{
    width: 40vw;
    margin: .75em 0;
    font-size: 14px;
    border: 1px solid #121323;
}

button {
    border: none;
    padding: 15px;
    background-color: #FF538F;
    color: white;
    font-weight: 500;
    cursor: pointer;
    margin: .75em 0;
}

@media screen and (max-width: 600px){
    .contact-container{
        height: 80%;
        display: block;
    }
    .c-bg{
        height: 100%;
    }
    .c-wrapper{
        flex-direction: column;
        padding: 0em;
        height: 80%;

    }
    .c-left{
        margin: 0 2em;
        height: 40vh;
    }
    .c-right{
        height: 60vh;
        align-items: center;
        text-align: center;
    }
    .c-desc{
        display: none;
    }
    form{
        margin-top: 0;
        display: flex;
        flex-wrap: wrap;
        padding: 1.5em 2em;
    }
    .c-info{
        margin: 1em 0;
    }
    input{
        width: 80vw;
    }
    .input-row1{
        width: 80vw;
        margin-right: 0;
    }
    textarea{
        width: 80vw;
    }
}