@font-face {
    font-family: Inconsolata;
    src: local('Inconsolata');
}
.skills-container{
    display: flex;
    background-color: #1f213f;
    height: 10vh;
    width: 100%;
    justify-content: space-evenly;
}
.s-wrap{
    width: 100% ;
    display: flex;
    align-content: center;
    height: 100%;
    text-align: center;
    background-color: #1f213f;
    justify-content: space-evenly;
    padding: 1% 15%
}


.s-img{
    max-height: 100%;
    max-width: 100%;
}

.s-text{
    color:white;
    font-family: Inconsolata, monospace;
    font-weight: 400;
    font-size: 2em;
  }



@media screen and (max-width: 480px) {

    .s-text{
        display: none;
    }

    .skills-container{
        height: 8vh;
    }

    .s-wrap{
        padding-top: 2%;
    }
    .s-img{
        max-height: 80%;
        max-width: 80%;
    }

}

@media screen and (max-width: 800px) {

    .s-text{
        display: none;
    }
    .s-wrap{
        padding: 1% 1%;
    }

}