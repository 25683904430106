.about-container{
    height: 80vh;
    display: flex;
    align-items: center;
    margin: 1.5em;
}

.a-left{
    flex:1;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100%;
    padding: 1.25em;
}

.a-right{
    flex: 1;
    padding: 1.25em;
}

.a-card.bg{
    position: absolute;
    width: 75%;
    top:7em;
    left: 3em;
    background-color: #9d3e69;

}

.a-card{
    width: 80%;
    height: 60vh;
    border-radius: 30px;
    position: relative;
    overflow: hidden;

}

.a-img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.a-title{
    font-size: 3em;
}
.a-desc{
    font-weight: 300;
    font-size: 1.4em;
}

@media screen and (max-width: 600px) {
    .about-container {
        flex-direction: column;
        text-align: center;
    }

    .a-left{
        height: 30vh;
        width: 100vw;
        padding: unset;
    }

    .a-desc{
        font-weight: 300;
        font-size: 1em;
    }

    .a-card{
        height: 30vh;
    }

    .a-card.bg{
        display: none;
    }
}